// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from "react";
import { render } from "react-dom";

import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ]
  },
});

import App from "../components/App";

document.addEventListener("DOMContentLoaded", () => {
  render(
    <ThemeProvider theme={theme} ><App /></ThemeProvider>,
    document.body.appendChild(document.createElement("div"))
  );
});